import axios, { AxiosRequestConfig } from 'axios';
import CommonUtils from '../utils/CommonUtils'
import { GlobalConsts } from '../config';

//默认 Content-Type: application/json，如果需要处理，可以在这里进行配置
//axios.defaults.headers
console.log("------------------------------", import.meta.env.AXIOS_BASE_URL)
// 创建实例
const axiosInstance = axios.create({
  //同意前缀，方便生产环境nginx转发到
  //baseURL:import.meta.env.AXIOS_BASE_URL as string,
  //send cookies when cross-domain requests
  baseURL: import.meta.env.DEV ? GlobalConsts.DevBaseUrl : GlobalConsts.ProdBaseUrl,
  withCredentials: true,
  timeout: 150000,
});

// request interceptor
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // 可以进行请求拦截处理，如添加请求头，token 等
    //config.headers?[GlobalConsts.TokenKey] = CommonUtils.getLocalStore(GlobalConsts.TokenKey)
    if(CommonUtils.getLocalStore(GlobalConsts.TokenKey)) {
      //headers是Record类型，不建议直接 config.headers?[GlobalConsts.TokenKey] 赋值
      config.headers = {
        [GlobalConsts.TokenKeyToBackend]: CommonUtils.getLocalStore(GlobalConsts.TokenKey)!,
        [GlobalConsts.UsernameKeyToBackend]: CommonUtils.getLocalStore(GlobalConsts.CurrentLoginUserName)!
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log("====================",res)
    //token过期
    if (res.code == 302 || res.code == 10003) {
      //认证异常，需要重新登录
      CommonUtils.clearLocalStore();
      if(window.location.pathname !== "/login"){
        window.location.href = "/login"
      } 
    }
    return Promise.resolve(res);
  },
  (error) => {
    //TODO:在此处理服务器异常或自定义错误异常
    //console.log('err' + error.response.status);
    return Promise.reject(error);
  }
);


// 封装 get 方法，类型为Get
const get: RequstType = async (url,params = {}) => {
  return await axiosInstance.get(url,{params});
};

// 封装 get 方法，类型为Delete
const doDelete: RequstType = async (url,params = {}) => {
  return await axiosInstance.delete(url,{params});
};

// 封装 get 方法，类型为Get
const post: RequstType = async (url, params) => {
  return await axiosInstance.post(url, params);
};

const postUpload: RequstType = async (url, params) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axiosInstance.post(url, params,config);
};
const postUploadWithoutCredentials: RequstType = async (url, params) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    withCredentials: false
  };
  return await axiosInstance.post(url, params,config);
};

// const postUploadByQiniuToken: RequstType = async (params) => {
//   const config = {
//     headers: { "Content-Type": "multipart/form-data" }
//   };
//   return await axiosInstance.post(GlobalConsts.QiniuUploadUrl, params,config);
// };


const uploadWithProgress: RequstType = async (url, params,cb:any) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: function (progressEvent) { //原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
          //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          cb&&cb(progressEvent);
      }
  },
  };
  return await axiosInstance.post(url, params,config);
};


const HttpUtils = {
  get,
  doDelete,
  post,
  postUpload,
  uploadWithProgress,
  postUploadWithoutCredentials
  // postUploadByQiniuToken
};

// 导出封装后的axios
export default HttpUtils;
