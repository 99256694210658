const TokenKey = "newxuepai-token";
const CurrentLoginUserName = "newxuepai-sys-username";
const CurrentLoginUserNo = "newxuepai-sys-userno";
const MenuUrl = "newxuepai-menu-url";
const CurrentLoginUserRoleId = "newxuepai-curUser-roleId";
const CurrentMenuTreeList = "newxuepai-menu-tree-list";
const TokenKeyToBackend = "token";
const UPDATE_PWD_FLAG = "updatePwdFlag";
const UsernameKeyToBackend = "username";

export const GlobalConsts = {
  DevBaseUrl: '/newxuepaiapi',
   // DevBaseUrl:'http://10.50.88.205:8081/',
  ProdBaseUrl: '/xuepaiapi',
  // ProdBaseUrl:'http://10.50.88.205:8081/',
  TokenKey,
  QiniuToken:'qiniuToken',
  QiniuFilePrefix:'qiniuFilePrefix',
  QiniuUploadUrl:'https://upload.qiniup.com/',
  CurrentLoginUserName,
  CurrentLoginUserNo,
  CurrentLoginUserRoleId,
  TokenKeyToBackend,
  UsernameKeyToBackend,
  MenuUrl,
  CurrentMenuTreeList,
  UPDATE_PWD_FLAG,
  MenuItems: 'menuItems',
  UserInfo: 'userInfo',
  DefaultProjectIdKey:'default-projectid',
  //1-待审核，2-已发布，3-草稿，5 隐藏，6 已拒绝
  DynamicInfoStatusList: [
    {
      id: 1,
      lable: '待审核',
    }, {
      id: 2,
      lable: '已发布',
    }, {
      id: 3,
      lable: '草稿',
    }, {
      id: 5,
      lable: '隐藏',
    }, {
      id: 6,
      lable: '已拒绝',
    }
  ],
  CommentTypeList: [
    {
      id: 1,
      lable: '文章',
    }, {
      id: 2,
      lable: '视频',
    }, {
      id: 3,
      lable: '动态',
    }
  ],
  GradeData: [
    '通用', '一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级', '高一', '高二', '高三', '不区分'
  ],
  GradeList: [
    { value: 1, label: '一年级' },
    { value: 2, label: '二年级' },
    { value: 3, label: '三年级' },
    { value: 4, label: '四年级' },
    { value: 5, label: '五年级' },
    { value: 6, label: '六年级' },
    { value: 7, label: '七年级' },
    { value: 8, label: '八年级' },
    { value: 9, label: '九年级' },
  ],
  SubjectData: [
    '通用', '语文', '数学', '英语', '科学', '综合', '物理', '化学', '生物', '历史', '地理', '道德与法治', '综合实践', '安全教育', 'STEM'
  ],
  SubjectList: [
    { value: 1, label: '语文' },
    { value: 2, label: '数学' },
    { value: 3, label: '英语' },
    { value: 4, label: '科学' },
    { value: 5, label: '道德与法治' },
    { value: 6, label: '历史与社会' },
  ],
  //1-待审核，2-已发布，3-草稿，5 隐藏，6 已拒绝
  ProjectMap:{
    1: '青云端H5网页',
    2: 'study.ooowin.com',
    3: 'xiezi.ooowin.com',
    4: '教科社合作—提供给教科社'
  },
  ProjectList:[{
    value: 1,
    label: '青云端H5网页'
  },{
    value: 2,
    label: 'study.ooowin.com'
  },{
    value: 3,
    label: 'xiezi.ooowin.com'
  },{
    value: 4,
    label: '教科社合作—提供给教科社'
  }],
  Semesters: [
    {
      id:1,
      label:'上学期'
    }
    ,{
      id:2,
      label:'下学期'
    } 
  ],
  PressList: [
    {
      id:1,
      label:'浙江教育出版社'
    }
    ,{
      id:2,
      label:'人民教育出版社'
    }, {
      id:4,
      label:'教育科学出版社'
    }, {
      id:99,
      label:'未知'
    }
  ]
}
