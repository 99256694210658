import HttpUtils from '../utils/HttpUtils'


interface ILoginParam {
  username: string,
  password: string,
  // captcha: string,
  // captchaKey: string
}

export default {
    getCaptcha(){
        return HttpUtils.get("/admin/auth/captcha")
    },
    login(data:ILoginParam){
        return HttpUtils.post("/mgr/login",data)
    }
}

