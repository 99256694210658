import { storageFactory } from "./LocalstorageFactory";
import { GlobalConsts } from '../config';

const local = storageFactory(() => localStorage);
//const session = storageFactory(() => sessionStorage);

function formatTime(time: string) {
  return time.substring(0, 16)
}



//IE10 以上支持
function Base64Encode(str: string) {
  console.log(str);
  return btoa(encodeURIComponent(str));
}
//IE10 以上支持
function Base64Decode(str: string) {
  return decodeURIComponent(atob(str));
}

function setLocalStore(key: string, value: string) {
  local.setItem(key, value)
}

function getLocalStore(key: string) {
  return local.getItem(key);
}
function clearLocalStore() {
  local.clear();
}

function isLogin() {
  if (getLocalStore(GlobalConsts.TokenKey)) {
    return true;
  }
  return false;
}


function getQiniuUploadUrl(data: {domain:string,key:string}){
  if(data&&data.domain&&data.key){
    return data.domain + "/" + data.key  
  }else {
    return ""
  }
}


function getGradeTitleById(id:number) {
  switch (id) {
    case 1:
      return '一年级';
    case 2:
      return '二年级';
    case 3:
      return '三年级';
    case 4:
      return '四年级';
    case 5:
      return '五年级';
    case 6:
      return '六年级';
    case 7:
      return '七年级';
    case 8:
      return '八年级';
    case 9:
      return '九年级';
    default:
      return ""
  }
}


// 获取同步学分类标题
function getEditionTitle(id:number) {

  switch (id) {
    case 1:
      return '浙江教育出版社';
    case 2:
      return '人民教育出版社';
    case 4:
      return '教育科学出版社';
    default:
      return ""
  }
}

function getCourseTitleById(id:number){
  switch (id) {
    case 1:
      return '语文';
    case 2:
      return '数学';
    case 3:
      return '英语';
    case 4:
      return '科学';
    case 5:
      return '道德与法治';
    case 6:
      return '历史与社会';
    default:
      return ""
  }
}

function simpleDeepClone(obj){
  return JSON.parse(JSON.stringify(obj));
}

// // iOS下拦截 window.open的问题
// function windowOpenUtils(url:string){
//   if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
//     setTimeout(()=>{
//       window.location.href = url
//     })
   
//   }else{
//     setTimeout(()=>{
//       window.open(url, '_blank')
//     })
//   }
//   return
// }

const CommonUtils = {
  formatTime,
  setLocalStore,
  getLocalStore,
  clearLocalStore,
  isLogin,
  Base64Encode,
  Base64Decode,
  getQiniuUploadUrl,
  getGradeTitleById,
  getEditionTitle,
  getCourseTitleById,
  simpleDeepClone
};


export default CommonUtils;
