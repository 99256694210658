import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CommonUtils from '../utils/CommonUtils';
import DashboardIndex from '../views/dashboard/Index.vue'
import LayoutIndex from '../views/layout/Index.vue'
import Login from "../views/Login.vue";
import { useMenuStore } from '../store/menuStore'


/**
 * 白名单路由，不需要任何权限的路由
 */
const whiteList = [
  "/dashboard/index",
  '/webpages/resDownload/item',
  '/SzkpAddBookPageItem',
  '/SzkpBookPageItemList',
  '/webpages/szkp/bookpage',
  '/webpages/szkp/bookpagelist',
  '/webpages/pyres',
  '/webpages/pylinkres',
  '/webpages/ywsylinkres',
  '/webpages/jiaokeshe/course/contents',
  '/webpages/jiaokeshe/course',
  '/webpages/jiaokeshe/course/pdfpreview',
  '/webpages/jiaokeshe/course/videoplay',
  '/webpages/jiaokeshe/course/itemlist',
  "/sys/rbac/user",
  "sys/rbac/role",
  "sys/rbac/menu",
  "/dashboard/mymemo",
  "/setting/profile",
  "/help/index",
  '/user/serviceChatDetail',
  "/sys/menuList",
  '/course/add',
  '/course/addChapterItem',
  '/kxsy/teacher/training/add'
];

//静态路由
const staticRouter = [
  {
    name: "LayoutIndex",
    path: "/",
    component: LayoutIndex,
    redirect: "/dashboard/index",
    children: [
      {
        name: "DashboardIndex",
        path: "dashboard/index",
        component: DashboardIndex,
      },
      {
        name: "Setting",
        path: "setting/profile",
        component: () => import("../views/setting/Profile.vue"),
      },
      {
        name: "HelpIndex",
        path: "help/index",
        component: () => import("../views/help/Index.vue"),
      },
      {
        name: "Page401",
        path: "dashboard/401",
        component: () => import("../views/Page401.vue"),
      },
    ],
  },

  {
    name: "Qrcode",
    path: "/qrcode",
    component: LayoutIndex,
    children: [
      {
        name: "QrcodeBatchList",
        path: "batchList",
        component: () => import("../views/qrcode/QrcodeBatchList.vue"),
      },
      {
        name: "QrcodeList",
        path: "List",
        component: () => import("../views/qrcode/QrcodeList.vue"),
      },
      {
        name: "QrcodeBatchContentList",
        path: "batchContentList",
        component: () => import("../views/qrcode/BatchContentList.vue"),
      },
      {
        name: "QrcodeViewLog",
        path: "viewlog",
        component: () => import("../views/qrcode/QrcodeViewLog.vue"),
      }
    ]
  },
  {
    name: "Kxsy",
    path: "/kxsy",
    component: LayoutIndex,
    children: [
      {
        name: "KxsyUsrList",
        path: "user/list",
        component: () => import("../views/kxsy/UserList.vue"),
      },
      {
        name: "KxsyTeacherDevelop",
        path: "teacher/training",
        component: () => import("../views/kxsy/TeacherTrainingList.vue"),
      },
      {
        name: "KxsyTeacherDevelopAdd",
        path: "teacher/training/add",
        component: () => import("../views/kxsy/TeacherTrainingEdit.vue"),
      }
    ]
  },


  {
    name: "SysRbac",
    path: "/sys",
    component: LayoutIndex,
    children: [
      {
        name: "SysUser",
        path: "userList",
        component: () => import("../views/sys/rbac/SysUser.vue"),
      },
      {
        name: "SysRole",
        path: "roleList",
        component: () => import("../views/sys/rbac/SysRole.vue"),
      },
      {
        name: "SysMenu",
        path: "menuList",
        component: () => import("../views/sys/rbac/SysMenu.vue"),
      },
      {
        name: "SysLog",
        path: "logList",
        component: () => import("../views/sys/other/SysLog.vue"),
      },
    ],
  },
  //课程中心
  {
    name: "CourseCenter",
    path: "/",
    component: LayoutIndex,
    children: [
      {
        name: "CourseList",
        path: "CourseList",
        component: () => import("../views/course/CourseList.vue"),
      },
      {
        name: "AddCourse",
        path: "course/add",
        component: () => import("../views/course/CourseAdd.vue")
      },
      ///AddCourseItem
      {
        name: "AddCourseItem",
        path: "course/addChapterItem",
        component: () => import("../views/course/CourseChapterEdit.vue")
      },
      {
        name: "CourseActiveCodeList",
        path: "CourseActiveCodeList",
        component: () => import("../views/course/CourseActiveCodeList.vue"),
      },
      {
        name: "QingyunDuanH5UserList",
        path: "QingyunDuanH5UserList",
        component: () => import("../views/course/QydH5UserList.vue"),
      },
      {
        name: "QingyunDuanH5ActivedList",
        path: "QingyunDuanH5ActivedList",
        component: () => import("../views/course/QydH5ActiveList.vue"),
      },
      {
        name: "QingzhiUserList",
        path: "QingzhiUserList",
        component: () => import("../views/course/QingzhiUserList.vue"),
      },
      {
        name: "QingzhiActivedList",
        path: "QingzhiActivedList",
        component: () => import("../views/course/QingzhiActiveList.vue"),
      }
    ]
  },
  //资源中心
  {
    name: "Resource",
    path: "/",
    component: LayoutIndex,
    children: [
      {
        name: "ResourceList",
        path: "ResourceList",
        component: () => import("../views/resource/ResourceList.vue"),
      },
      {
        name: "JiaoyusheDownload",
        path: "jiaoyusheDownload",
        component: () => import("../views/resource/JiaoyusheResourceList.vue"),
      }
    ]
  },
  // 知识卡片
  {
    name: "KnowledgeCard",
    path: "/",
    component: LayoutIndex,
    children: [
      {
        name: "SzkpBookPageList",
        path: "SzkpBookPageList",
        component: () => import("../views/knowledgeCard/PageList.vue"),
      },
      {
        name: "SzkpBookPageItemList",
        path: "SzkpBookPageItemList",
        component: () => import("../views/knowledgeCard/PageItemList.vue"),
      },
      {
        name: "SzkpAddBookPageItem",
        path: "SzkpAddBookPageItem",
        component: () => import("../views/knowledgeCard/AddPageItem.vue"),
      },
      {
        name: "SzkpAddBookPageViewLog",
        path: "szkpbookpage/viewlog",
        component: () => import("../views/knowledgeCard/ViewLog.vue"),
      },
    ]
  },
  {
    name: "baseService",
    path: "/baseService",
    component: LayoutIndex,
    children: [
      {
        name: 'baseUserList',
        path: 'userList',
        component: () => import("../views/baseservice/UserList.vue"),
      }, {
        name: 'smsLogList',
        path: 'smsLog',
        component: () => import("../views/baseservice/SmsLogList.vue"),
      }, {
        name: 'bizConfig',
        path: 'bizConfig',
        component: () => import("../views/baseservice/BizConfig.vue"),
      }
    ]
  },
  {
    name: "ShortLink",
    path: "/shortLink/list",
    component: LayoutIndex,
    children: [
      {
        name: 'ShortLinkList',
        path: '',
        component: () => import("../views/shortlink/TheList.vue"),
      }
    ]
  },
  // 用户管理（APP注册用户）
  {
    name: "User",
    path: "/user",
    component: LayoutIndex,
    children: [
      {
        name: "UserList",
        path: "list",
        component: () => import("../views/user/UserList.vue"),
      },
      {
        name: "FeedbackList",
        path: "feedback",
        component: () => import("../views/user/FeedbackList.vue"),
      },
      {
        name: "ServiceChatList",
        path: "serviceChat",
        component: () => import("../views/user/ServiceChatList.vue"),
      },
      {
        name: "ServiceChatDetail",
        path: "serviceChatDetail",
        component: () => import("../views/user/ServiceChatDetail.vue"),
      },
    ],
  },
  {
    name: "ResDownload",
    path: "/ResDownloadList",
    component: LayoutIndex,
    children: [
      {
        name: 'ResDownloadList',
        path: '',
        component: () => import("../views/resDownload/ResDownloadList.vue"),
      }
    ]
  },
  {
    name: "webpages",
    path: "/webpages",
    // component: LayoutIndex,
    children: [
      {
        name: "WebPagesResDownloadItem",
        path: "resDownload/item",
        component: () => import('../views/WebPages/ResDownload/ResDownloadItem.vue'),
      },
      {
        name: "WebPagesSzkpBookPage",
        path: "szkp/bookpage",
        component: () => import('../views/WebPages/Szkp/BookPage.vue'),
      },
      {
        name: "WebPagesSzkpBookList",
        path: "szkp/bookpagelist",
        component: () => import('../views/WebPages/Szkp/BookPageList.vue'),
      },
      {
        name: "WebPagesPyRes",
        path: "pyres",
        component: () => import('../views/WebPages/PyRes/index.vue'),
      },
      {
        name: "WebPagesYwsyLinkRes",
        path: "ywsylinkres",
        component: () => import('../views/WebPages/PyRes/ywsyLinkRes.vue'),
      },
      {
        name: "WebPagesPyLinkRes",
        path: "pylinkres",
        component: () => import('../views/WebPages/PyRes/linkRes.vue'),
      },
      // 提供给教科社的免费课程
      {
        name: "WebPagesJiaokesheCourse",
        path: "jiaokeshe/course",
        component: () => import('../views/WebPages/jiaokesheCourse/course.vue'),
      },
      {
        name: "WebPagesJiaokesheCourseVideo",
        path: "jiaokeshe/course/videoplay",
        component: () => import('../views/WebPages/jiaokesheCourse/videoplay.vue'),
      },
      {
        name: "WebPagesJiaokesheCoursePdf",
        path: "jiaokeshe/course/pdfpreview",
        component: () => import('../views/WebPages/jiaokesheCourse/pdfpreview.vue'),
      },
      // 教科社课程目录
      {
        name: "WebPagesJiaokesheContents",
        path: "jiaokeshe/course/contents",
        component: () => import('../views/WebPages/jiaokesheCourse/contents.vue'),
      },
      // 教科社课程子目录
      {
        name: "WebPagesJiaokesheItemList",
        path: "jiaokeshe/course/itemlist",
        component: () => import('../views/WebPages/jiaokesheCourse/itemList.vue'),
      }
    ],
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: staticRouter as RouteRecordRaw[]
})

//全局前置守卫, 路由权限与后台返回权限校验
router.beforeEach(async (to, from) => {
  const toPath = to.path;
  const mainMenu = useMenuStore();
  if (toPath == "/login" && CommonUtils.isLogin()) {
    return "/dashboard/index"
  } else if (toPath == "/login" && !CommonUtils.isLogin()) {
    return true;
  } else if (CommonUtils.isLogin()) {
    let menus = mainMenu.menus;
    console.log(menus)
    if (menus.length == 0) {
      //console.log("-----------------当前没有菜单，设置菜单后检查权限------------------", toPath)
      //当前没有菜单，设置菜单
      await mainMenu.setMenus();
      console.log(mainMenu.menus)
      //检查当前访问路由是否有权限
      //mainMenu.setMenus(); 是异步的
      const isTrue = validHasPathPermission(mainMenu.menus, toPath);
      console.log("-----------------当前没有菜单，设置菜单后检查权限--------after:", isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    } else {
      //console.log("-----------------当前有菜单,检查权限------------------", menus, toPath)
      //检查当前访问路由是否有权限
      const isTrue = validHasPathPermission(menus, toPath);
      console.log("-----------------当前有菜单,检查权限--------after:", isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    }
  } else {
    //白名单，未登录，直接放行
    if (whiteList.includes(toPath)) {
      return true;
    } else {
      return "/login"
    }

  }
})

//@ts-ignore
const validHasPathPermission = (menus, toPath): boolean => {
  //console.log("------validHasPathPermission-----------", menus, toPath)
  let isTrue = false;
  if (whiteList.includes(toPath)) {
    isTrue = true;
  } else {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].frontRoute == toPath) {
        isTrue = true;
        break;
      } else {
        let arr = menus[i].children;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].frontRoute == toPath) {
            isTrue = true;
            break;
          }
        }
      }
    }
  }
  return isTrue;
}
export default router

